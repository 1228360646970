<template>
<div class="background" @click="clickBack()">
    <div v-if="dialogCamera">
        <v-dialog v-model="dialogCamera" max-width="700">
            <v-card>
                <v-col>
                    <qrcode-stream @decode="onDecode" @init="onInit" />
                </v-col>
            </v-card>
        </v-dialog>
    </div>
    <div>
        <v-text-field class="inputControl" number color="white" autofocus ref="textcontrol" @keyup.enter="access()" dark maxlength=32 v-model="document" @click:append-outer="clickCamera()" hide-details="" dense block outlined append-outer-icon="mdi-camera"></v-text-field>

        <v-col style="padding-top: 0px; padding-bottom: 0px">

            <v-card v-if="person != null" class="d-flex d-sm-none justify-center " rounded="" :color="person.color">
                <div v-if="person.MahMessage" class="d-flex  justify-center ">
                    <p class="text-center d-none d-sm-flex" style="padding: 6px; color: white; font-size: 48px">
                        <v-row justify="center"><b>{{ person.MahMessage }}</b></v-row>
                    </p>
                    <p class="text-center d-flex d-sm-none" style="padding: 6px; color: white; font-size: 32px">
                        <v-row justify="center"><b>{{ person.MahMessage }}</b></v-row>
                    </p>
                </div>
            </v-card>

            <v-col style="padding: 0px">
                <v-card min-height="440" style="background:rgba(255,255,255,0.9); padding:0px;margin:0px;">
                    <v-card-title style="background:#5175AF;">
                        <v-row>
                            <v-col class='d-flex justify-center '>
                                <h1 style="font-size: 24px !important ;color:white;height:15px !important;" class=" d-flex d-sm-none text-center">
                                    {{ 'IMP' }}
                                </h1>
                                <h1 style="font-size: 24px !important ;color:white;height:15px !important;" class=" d-none d-sm-flex text-center">
                                    {{ 'Inmobiliaria Miraflores Perú' }}
                                </h1>
                            </v-col>
                        </v-row>
                    </v-card-title>

                    <v-divider></v-divider>
                    <v-col>
                        <v-row>

                            <v-col class="s-color-title mt-10" style="" cols="12">
                                <h1 v-if="person.NtpName" style="font-size: 60px !important; line-height: 1.1" class="s-color-title d-none d-sm-flex text-center">
                                    <v-row justify="center">
                                        {{
                        person.NtpPaternalSurname +
                        " " +
                        person.NtpMaternalSurname +
                        " " +
                        person.NtpName
                      }}</v-row>
                                </h1>
                                <h1 v-if="person.NtpName" style="font-size: 32px !important" class="d-flex d-sm-none s-color-title text-center">
                                    {{
                      person.NtpPaternalSurname +
                      " " +
                      person.NtpMaternalSurname +
                      " " +
                      person.NtpName
                    }}
                                </h1>

                                <h1 v-else style="font-size: 60px !important" class="s-color-title text-center">
                                    CONTROL DE ACCESO
                                </h1>

                                <h1 style="font-size: 42px !important" class="d-none d-sm-flex s-color-title text-center">
                                    <v-col class="d-flex justify-center" style="padding:0px">{{
                      person.PrsDocumentNumber
                    }}</v-col>
                                </h1>
                                <h1 style="font-size: 24px !important" class="text-center d-sm-none s-color-title">
                                    {{ person.PrsDocumentNumber }}
                                </h1>
                                <h1 style="
                      font-size: 52px !important;
                      color: var(--v-warning-base);
                      line-height: 1;
                    " class="d-none d-sm-flex text-center">
                                    <v-col class="d-flex justify-center" style="padding:0px"> {{ person.PstName }}</v-col>
                                </h1>
                                <h1 style="
                      font-size: 26px !important;
                      color: var(--v-warning-base);
                    " class="text-center d-sm-none">
                                    {{ person.PstName }}
                                </h1>
                                <h1 v-if="person.AreName" style="font-size: 36px !important" class="s-color-title d-none d-sm-flex">
                                    <v-col class="d-flex justify-center" style="padding:0px">{{ person.AreName }}</v-col>
                                </h1>
                                <h1 v-if="person.AreName" style="font-size: 24px !important" class="s-color-title d-sm-none text-center">
                                    {{ person.AreName }}
                                </h1>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-card>
            </v-col>

            <v-col style="padding-left:0px;padding-right:0px;">
                <v-row class="d-none d-sm-flex">
                    <v-col style="padding-top: 10px">
                        <v-card rounded="" :color="person.color">
                            <div v-if="person.MahMessage">
                                <p class="text-center d-none d-sm-flex" style="padding: 6px; color: white; font-size: 48px">
                                    <v-row justify="center"><b>{{ person.MahMessage }}</b></v-row>
                                </p>
                                <p class="text-center d-flex d-sm-none" style="padding: 6px; color: white; font-size: 32px">
                                    <v-row justify="center"><b>{{ person.MahMessage }}</b></v-row>
                                </p>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-col>
    </div>
</div>
</template>

<script>
import {
    QrcodeStream
} from "vue-qrcode-reader";
import ControlAccessService from "@/services/Management/ControlAccess";
export default {
    name: "TimeRealReserve",
    components: {
        QrcodeStream
    },
    props: {},

    data: () => ({
        dialogCamera: false,
        document: "",
        timeRefresh: 0,
        person: {
            NtpFullName: "CONTROL DE ACCESO",
            PstName: "",
            PrsDocumentNumber: "",
            color: "white",
        },
    }),

    created() {
        this.start();
        let timerId = setInterval(() => {
            this.timeRefresh = this.timeRefresh + 1000;
            if (this.timeRefresh >= localStorage.getItem("TimeToken") / 2) {
                $("body").trigger("mousemove");
                clearInterval(timerId);
            }
        }, 1000);
    },

    methods: {
        onDecode(result) {
            this.document = result;
            this.access();
            this.dialogCamera = false;
        },

        async onInit(promise) {
            try {
                await promise;
            } catch (error) {
                console.log(error);
                if (error.name === "NotAllowedError") {
                    this.error = "ERROR: you need to grant camera access permisson";
                } else if (error.name === "NotFoundError") {
                    this.error = "ERROR: no camera on this device";
                } else if (error.name === "NotSupportedError") {
                    this.error = "ERROR: secure context required (HTTPS, localhost)";
                } else if (error.name === "NotReadableError") {
                    this.error = "ERROR: is the camera already in use?";
                } else if (error.name === "OverconstrainedError") {
                    this.error = "ERROR: installed cameras are not suitable";
                } else if (error.name === "StreamApiNotSupportedError") {
                    this.error = "ERROR: Stream API is not supported in this browser";
                }
            }
        },

        clickCamera() {
            this.dialogCamera = true;
        },
        clickBack() {
            this.$refs.textcontrol.focus();
        },

        start() {
            this.$fun.fullScreen();
            this.$nextTick().then(() => {
                this.$refs.textcontrol.focus();
            });
        },

        cancel() {
            this.$router.go(-1);
        },

        access() {
            if (this.document.length > 3) {
                ControlAccessService.personaccess(
                    this.document,
                    this.$fun.getUserID(),
                    1,
                    1
                ).then((r) => {

                    this.document = "";
                    this.person = r.data;
                    if (this.person.TypeError == 0) this.person.color = "success";
                    else if (this.person.TypeError == 1) {
                        this.person.color = "red";
                        /* this.$fun.soundAlert(); */

                    }
                    this.clickBack();

                });
            }
        },
    },

};
</script>

<style>

</style>
